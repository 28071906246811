.file-upload-btn-container {
  display: flex;
  padding: 20px 24px 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  text-align: center;
  cursor: pointer;

  border-radius: 12px;
  border: 2px solid #118EB7;
  background: #F3F9FB;
}

.file-upload-btn-container label {
  cursor: pointer;
}


.file-upload-btn-container .selected-file-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.file-upload-btn-container .photo-text {
  color: var(--gray-600, #475467);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.file-upload-btn-container .photo-text p {
  margin-bottom: 0;
}

.file-upload-btn-container .photo-text p:last-of-type {
  margin: 0;
}

.file-upload-btn-container .photo-text b {
  color: var(--Azul-oscuro, #000F24);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}