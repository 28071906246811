.textarea-container textarea {
  width: 900px;
  height: 900px;
  border: 1px solid var(--gray-300, #F2F4F7);
  padding: 20px;
  overflow-x: hidden;
  outline: none;
  resize: none;
}

.textarea-container textarea:focus {
  border-color: var(--Gray-100, #F2F4F7);
}