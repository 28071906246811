.checkbox-container {
  display: flex;
}

.checkbox-container label {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}