.criteriabox-container .rows {
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
}

.criteriabox-container button {
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
}

.criteriabox-container img {
  cursor: pointer;
}


.criteriabox-container input {
  width: 100%;
}

.criteriabox-container .criteria-row {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
}

.criteriabox-container .criteria-row > div {
  display: flex;
  gap: 16px;
  width: 70%;
  align-items: center;
}

.criteriabox-container .criteria-row > div > * {
  flex-grow: 1;
}

.criteriabox-container .buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}


