.permissions-container {
  width: 100%;
  position:relative;
}

.permissions-container > div:first-of-type {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--Gray-200, #EAECF0);
}

.permissions-container h2 {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding: 30px;
}

.permissions-container p,
.permissions-container h3 {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.permissions-container .elements-container {
  padding: 20px 30px;
}

.permissions-container .elements-container .columns-container {
  justify-content: normal;
}

.permissions-container .elements-container > div {
  display: flex;
}

.permissions-container .elements-container > div p {
  margin-right: 10%;
}

.permissions-container .elements-container > div > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.permissions-container .elements-container > div > div h3,
.permissions-container .columns-container p:nth-child(2){
  width: 250px;
}


.permissions-container .columns-container p:nth-child(2) {
  margin-right: 0;
}


.permissions-container .elements-container > div > div > div {
  display: flex;
}

.permissions-container .elements-container > div > div > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.permissions-container .elements-container .columns-container p:first-of-type {
  margin-right: 8%;
}

.permissions-container .elements-container .columns-container p {
  color: var(--Gray-2, #4F4F4F);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.permissions-container .elements-container label {
  display: flex;
  justify-content: space-between;
  min-width: 135px;
}

.permissions-container .elements-container input {
  width: auto !important;
}

.permissions-container button {
  width: fit-content;
  margin: auto 30px;
}

/* Machacamos este estilo */
.crear-editar-page.rol .main-content .elements > div:first-of-type{
  border: none;
  margin-bottom: 29px;

}
.crear-editar-page.rol .main-content .elements > div {
  padding: 0;
}