.crear-editar-page .main-content {
  padding: 30px 24px;
}

.crear-editar-page .headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crear-editar-page .headers > div:first-of-type button {
  padding-left: 0;
  width: fit-content;
}

.crear-editar-page .main-content .headers-button-container {
  display: flex;
  width: 40%;
  gap: 10px;
}

.crear-editar-page .main-content .elements > div {
  border: 1px solid var(--Gray-200, #EAECF0);
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  gap: 10px;
  padding-right: 35%;
  align-items: center;
}

/* Estilos específicos para el primer contenedor */
.crear-editar-page .main-content .elements > div:first-of-type,
.crear-editar-page .main-content .elements > div:nth-of-type(2n){
  border-radius: 8px 8px 0 0;
}

.crear-editar-page .navigation-bar {
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #10182880 6%, rgba(0,212,255,0) 19%);
}

/* Estilos específicos para el último contenedor */
.crear-editar-page .main-content .elements > div:last-of-type {
  border-radius: 0 0 8px 8px;
}

.crear-editar-page  .main-content .elements label {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.crear-editar-page .main-content .elements .input-container,
.crear-editar-page .main-content .elements .select-container,
.crear-editar-page .main-content .elements input {
  width: 100%;
}

.crear-editar-page .main-content .buttons-container {
  display: flex;
  justify-content: center;
  max-width: 400px;
  margin: 29px auto;
  gap: 20px;
}

.crear-editar-page .main-content .file-upload-btn-container {
  width: 80%;
}

.crear-editar-page .main-content .image-container-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.crear-editar-page .main-content .image-container-row > img {
  border-radius: 200px;
  background: var(--gray-100, #F2F4F7);
  width: 64px;
  height: 64px;
  border-radius: 100%;
}