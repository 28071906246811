.modal-alert-container {
  display: flex;
  width: 560px;
  padding: 16px 16px 48px 16px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 5px;
  z-index: 1000;
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.modal-alert-container .modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-alert-container .modal-header img {
  cursor: pointer;
}

.modal-alert-container .modal-body img {
  margin-bottom: 20px;
  width: 80px;
}

.modal-alert-container .modal-body p {
  margin: 0;
}

.modal-alert-container .modal-body p:first-of-type {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.modal-alert-container .modal-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.modal-alert-container .modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
  width: 75%;
  max-width: 400px;
}

.modal-alert-container .error .modal-footer primary-error {
  background-color: var(--Red, #EB5757);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

