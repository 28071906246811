.pagination-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

/* Ocultamos los botones < > de la paginación por defecto */
.pagination-container .MuiPagination-ul li:first-child,
.pagination-container .MuiPagination-ul li:last-child {
  display: none;
}

.pagination-container button {
  color: var(--gray-600, #475467);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  background: none;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.pagination-container .MuiPagination-ul {
  color: var(--gray-800, #1D2939);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.pagination-container .MuiPaginationItem-textPrimary {
  color: var(--gray-800, #1D2939);
  border-radius: 8px;
  display: flex;
  width: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.pagination-container .MuiPaginationItem-textPrimary.Mui-selected {
  color: var(--gray-800, #1D2939);
  background-color: var(--gray-200, #EAECF0);
}