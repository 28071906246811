.select-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.select-container .label {
  margin-bottom: 6px;
  line-height: 20px;
}

.select-container select {
  border-color: var(--Gray-200, #667085);
  padding: 10px 30px 10px 14px;
  border-radius: 8px;
  background: var(--base-white);

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../icons/chevron-down.svg') no-repeat 97% center;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 44px;
}

.select-container select:focus,
.select-container select:focus-within {
  outline: none;
  border-color: var(--Gray-300, #667085);
}

.select-container select option {
  display: flex;
  padding: 2px 6px;
  align-items: center;

  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.select-container select option:hover {
  background: var(--Amarillo-claro, #FFEFD6);
}