.radio-button-container {
  p {
    color: var(--gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }

  .label {
    display: inline-flex;
    margin-right: 20px;

    input[type="radio"] {
      appearance: none;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      cursor: pointer;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid var(--gray-300, #D0D5DD);
        border-radius: 50%;
        position: absolute;
        left: 0;
        box-sizing: border-box;
        top: -3px;
      }

      &:checked {
        &:before {
          width: 10px;
          height: 10px;
          top: 0;
          box-shadow: 0 0 0 1px #fff, 0 0 0 3px #6D6E6D; /* Doble borde naranja y blanco cuando está marcado */
          background-color: #6D6E6D; /* Fondo naranja cuando está marcado */
        }
      }
    }
  }
}